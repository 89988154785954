<template>
  <div class="input-wrapper" style="position:relative;">
    <input
        type="text"
        v-model="inputValue"
        @focusin="onFocus"
        @input="onInput"
        class="included"
        :class="{'input-thin': thin}"
        :style="input_style"
        v-click-outside="{
                            handler: onClose,
                            include: included
                          }"
        >
    <div class="select-wrapper elevation-5" v-if="showSelect">
      <template v-if="showLabel">
        <v-list dense class="included">
          <v-list-item>
            <v-list-item-icon>
              <v-icon size="34" color="success">
                mdi-alert-circle-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="font-weight-medium">
              Почніть вводити текст для пошуку
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <template v-else>
        <v-list dense v-if="filteredData.length" class="included">
          <v-list-item
              v-for="(elem, index) in filteredData"
              :key="index"
              @click.stop="selectEvent(elem)"
              class="pl-2 pr-8 text-break"
              style="border-bottom: 1px solid #d4d4d4; border-radius: 0">
            <v-list-item-content v-html="highlightSearch(elem[itemText])" class="list-content"/>
          </v-list-item>
        </v-list>
        <v-list v-else dense class="included">
          <v-list-item>
            <v-list-item-icon>
              <v-icon size="34" color="error lighten-1">
                mdi-alert
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="font-weight-medium">
              Жодного запису не знайдено
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </div>
  </div>
</template>

<script>
import SelectAPI from '@/utils/axios/accounting/contractor_contract'

export default {
  name: "ACC_FlatSearchContract",
  props: {
    row_num: {
      type: Number,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    itemText: {
      type: String,
      default: 'text'
    },
    itemSearch: {
      type: String,
      default: 'text'
    },
    itemValue: {
      type: String,
      default: 'value'
    },
    thin: {
      type: Boolean,
      default: false
    },
    input_style: {
      type: String,
      default: ''
    },
    flat_id: {
      type: Number,
      default: null
    }
  },
  computed: {
    filteredData() {
      const input_text = `${this.inputValue}` || ''
      if (this.inputValue) {
        return this.entries.filter(item => {
          return item[this.itemSearch].toLowerCase().indexOf(input_text.toLowerCase()) > -1
        }).slice(0, 300)
      } else {
        return this.entries.slice(0, 300)
      }
    }
  },
  data() {
    return {
      showSelect: false,
      showLabel: false,
      // entries: [],
      inputValue: this.value,
      selected: null,
      entries: []
    }
  },
  methods: {
    onFocus() {
      if (this.entries.length) {
        this.showSelect = true
        this.showLabel = false
      } else {
        SelectAPI.select_by_flat_id({flat_id: this.flat_id || null})
            .then(response => response.data)
            .then(data => {
              this.entries = data

              this.showSelect = true
              this.showLabel = false
            })
      }
    },
    onInput() {
      if (this.entries.length) {
        this.showSelect = true
        this.showLabel = false
      } else {
        SelectAPI.select_by_flat_id({flat_id: this.flat_id || null})
            .then(response => response.data)
            .then(data => {
              this.entries = data

              this.showSelect = true
              this.showLabel = false
            })
      }
    },
    selectEvent(item) {
      this.inputValue = item[this.itemValue]
      this.selected = item
      this.$emit('selectChange', Object.assign({}, { row_num: this.row_num, ...item }))
      // this.selected = null
      this.showSelect = false
    },
    onClose () {
      this.showSelect = false
      this.showLabel = false
      if (!this.selected) {
        this.inputValue = `${this.selected ? this.selected[this.itemValue] : this.value}`
      }
    },
    highlightSearch(text) {
      if (!text) return ''
      if (this.showSelect && !this.showLabel) {
        return text.replace(new RegExp(this.inputValue, "g"), "<strong>" + this.inputValue + "</strong>");
      } else {
        return text
      }
    },
    included() {
      return [document.querySelector('.included')]
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(value_n, value_o) {
        if (value_n !== value_o) {
          if (!this.inputValue) {
            SelectAPI.select_by_flat_id({flat_id: this.flat_id || null})
                .then(response => response)
                .then(data => {
                  this.entries = data
                })
          }
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
  input {
    width: 84%;
    display: block;
    outline: none;
    border-bottom: 2px solid rgba(0, 0, 0, .2);
    padding: 2px 4px 2px 0;
    transition: all .2s ease-in-out;

    &:focus {
      border-color: var(--v-success-base);
      font-weight: 500;
    }
  }
  .input-thin {
    border-bottom: 1px solid rgba(0, 0, 0, .2) !important;
  }
  .error-input {
    input {
      border-bottom: 2px solid #f09995;
    }
  }
  .select-wrapper {
    position: fixed;
    background: white;
    z-index: 1;
    width: max-content;
    max-width: 400px;
    display: flex;
    flex: 1;
    overflow: overlay;
    max-height: 400px;
  }
  .list-content {
    display: block !important;
    &:deep(strong) {
      color: #4caf50;
      font-weight: 600;
    }
  }
</style>